import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';



/* -------------------------------------------------------------------------- */
/*                                LANDING PAGE                                */
/* -------------------------------------------------------------------------- */



const LandingPage = () => {


  return (
    <div>
      <Helmet>
        <title>Optera Labs</title>
      </Helmet>
      <Header />

    </div>
  );
};

export default LandingPage;
