import React from 'react';

class Header extends React.Component {
  render() {
    return (
        <div class="header level blur-background">
            {/* <!-- Left side --> */}
            <div class="level-left">
                <div class="level-item">
                <p class="header-title">
                    Optera Labs
                </p>
                </div>
            </div>

            {/* <!-- Right side --> */}
            <div class="level-right">
                <p class="level-item header-item">Publications</p>
                <p class="level-item header-item">Contacts</p>
            </div>
        </div>
      );
    }
}

export default Header;